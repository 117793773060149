.App {
  text-align: center;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

body.light-mode {
  background-color: #dbdbdb;
  color: #333;
  transition: background-color 0.3s ease;
}
body.dark-mode {
  background-color: #464545;
  color: #1a1919;
  transition: background-color 0.3s ease;
}

body.dark-mode h4 {
  color: #dbdbdb;
}

.MuiInputBase-root {
  background-color: #e0e0e0;
}

.MuiButtonGroup-contained {
  background-color: #e0e0e0;
}